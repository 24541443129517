import React, { useEffect, useState } from 'react';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { getMuiTheme } from "../../components/commonColumns/commonComponents";
import MUIDataTable from "mui-datatables";
import { LunchnLearnColumns, options } from './commonProps';
import AddEventReportsModal from "./AddEventReportsModal";
import RenderDateFilter from "./RenderDateFilter";
import { useSelector, connect } from 'react-redux';
import { getEventReports } from "../../store/EventTracker";

const LunchnLearn = (props) => {

    const [addLicenseSeeding, setAddLicenseSeeding] = useState(false)
    const { filters, setFilters, getLunchnLearn, lunchnLearn, isLoading } = props

    const { user } = useSelector(state => state.auth)
    const permissions = user.permissions;

    const handleAddClick = async () => {
        fetchData()
        setAddLicenseSeeding(!addLicenseSeeding)
    }

    const fetchData = () => {
        var input = {
            event_type: "Lunch & Learn",
            start_date: filters.startDate,
            end_date: filters.endDate
        }
        getLunchnLearn(input)
    }

    useEffect(() => {
        fetchData()
    }, [filters])

    window.addEventListener('keydown', (e) => {

        e.preventDefault();
        console.log("e.key", e.key)
        if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
            e.preventDefault();
        }
        // Prevent default actions for Print Screen (PrtScn)
        if (e.key === 'PrintScreen') {
              console.log("print screen")
              e.preventDefault();
        }
      
        // Prevent default actions for the Windows key
        if (e.key === 'Meta') { // Meta key represents the Windows key
          console.log("meta")
              e.preventDefault();
        }
    
      });

    return (
        <>
        <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
                            <b>Lunch & Learn List</b>&nbsp;&nbsp;&nbsp;
                            {permissions.includes("canCreateEventReport") && <span onClick={() => handleAddClick()} className={"badges badge-pill cursor-pointer badge-primary"}> Add </span>}&nbsp;&nbsp;
                            <RenderDateFilter filters={filters} setFilters={setFilters} />
                        </div>
                        }
                        data={lunchnLearn}
                        columns={LunchnLearnColumns}
                        options={options(isLoading)}
                        className="no-select" 
                    />
                </MuiThemeProvider>
            </React.Fragment>
            {addLicenseSeeding && <AddEventReportsModal show={addLicenseSeeding} hide={handleAddClick} from="Lunch & Learn" />}
        </div>
        </>
    )
}


const mapStateToProps = (state) => ({
    isFetching: state.EventTracker.isFetching,
    lunchnLearn: state.EventTracker.lunchnLearn
});

const mapDispatchToProps = (dispatch) => ({
    getLunchnLearn: (params) => dispatch(getEventReports(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(LunchnLearn);